// USADO PRA FAZER ROTAS PRA NOVIOS E CONVIDADOS, VIDE ANOTACOES.MD
// E QUERIES DO GATSBY
import React from 'react';
import { graphql } from 'gatsby';
import SuppliersListPage from './suppliersList';

export const SupplierPage = () => {
  return (
    <SuppliersListPage />
  );
};

export default SupplierPage;

export const pageQuery = graphql`
  query SellerQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
